const PasswordGame = {
    timerElem: undefined,
    checkPasswordElem: undefined,
    gameFormElem: undefined,
    movesCounterElem: undefined,
    passwordInputElem: undefined,
    truePassword: '',
    currentPassword: '',
    timerIntervalFn: undefined,
    timeCounter: 0,
    movesCounter: 0,
    visibleNumbers: 0,
    redirectUrl: 'https://google.com',

    init() {
        this.timerElem = document.getElementById('time');
        this.checkPasswordElem = document.getElementById('submit');
        this.truePassword = document.getElementById('true-password').value;
        this.gameFormElem = document.querySelector('.numpad');
        this.movesCounterElem = document.getElementById('moves');
        this.passwordInputElem = document.querySelector("input[name='password']");
        this.gameFormElem.addEventListener('animationend', () => {
            this.gameFormElem.classList.remove('shake');
        });
        this.startTimer();
        this.registerButtonClick();
        this.registerCheckPassword();
    },

    startTimer() {
        setTimeout(() => {
            this.timerIntervalFn = setInterval(() => {
                this.timerElem.innerText = (++this.timeCounter).toString();
            }, 1000);
        }, 500);
    },

    stopTimer() {
        clearInterval(this.timerIntervalFn);
        this.timeCounter = 0;
        this.timerElem.innerText = '0';
    },

    registerButtonClick() {
        document.querySelectorAll('.button.empty').forEach(button => {
            button.addEventListener('click', e => {
                e.preventDefault();
                button.classList.add('hidden');
                button.nextElementSibling.classList.remove('hidden');
                this.currentPassword += button.nextElementSibling.dataset.number;
                this.incrementVisibleNumbers();
                this.incrementMoves();
            });
        });

        document.querySelectorAll('.button:not(.empty)').forEach(button => {
            button.addEventListener('click', e => {
                e.preventDefault();
                this.currentPassword += button.dataset.number;
                this.incrementVisibleNumbers();
                this.incrementMoves();
            });
        });
    },

    hideAllNumbers() {
        document.querySelectorAll('.button.empty.hidden').forEach(button => {
            button.classList.remove('hidden');
            button.nextElementSibling.classList.add('hidden');
        });
    },

    incrementVisibleNumbers() {
        this.visibleNumbers++;
        if (this.visibleNumbers > 1) {
            setTimeout(() => {
                this.hideAllNumbers();
                this.visibleNumbers = 0;
            }, 300);
        }
    },

    incrementMoves() {
        this.movesCounter++;
        this.movesCounterElem.innerText = this.movesCounter.toString();
    },

    resetMoves() {
        this.movesCounter = 0;
        this.movesCounterElem.innerText = '0';
    },

    registerCheckPassword() {
        this.checkPasswordElem.addEventListener('click', e => {
            e.preventDefault();
            if (this.truePassword !== this.currentPassword) {
                this.gameFormElem.classList.add('shake');
                window.location.href = this.redirectUrl;
            } else {
                this.passwordInputElem.value = this.currentPassword;
                this.gameFormElem.submit();
            }
            this.currentPassword = '';
            this.timeCounter = 0;
            this.resetMoves();
            this.visibleNumbers = 0;
            this.hideAllNumbers();
            this.stopTimer();
            this.startTimer();
        });
    }
}


export default PasswordGame;
