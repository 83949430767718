const PinPad = {
    numberInput: undefined,
    init() {
        this.numberInput = document.getElementById('pinpad-input');
        if (this.numberInput.value == null) {
            this.numberInput.focus();
        }

        document.querySelectorAll('.pinpad-number[data-number]').forEach(button => {
            button.addEventListener('click', () => {
                this.numberInput.value += button.dataset.number;
                this.numberInput.focus();
            });
        });

        if (document.getElementById('reset')) {
            document.getElementById('reset').addEventListener('click', () => {
                this.numberInput.value = '';
                this.numberInput.focus();
            });
        }

        if (document.querySelector(".pinpad-number[type='reset']")) {
            document.querySelector(".pinpad-number[type='reset']").addEventListener('click', () => {
                this.numberInput.focus();
            });
        }

        document.getElementById('undo').addEventListener('click', () => {
            this.numberInput.value = this.numberInput.value.substring(0, this.numberInput.value.length - 1);
            this.numberInput.focus();
        });
    }
}

export default PinPad;
