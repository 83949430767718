import { gsap } from 'gsap';

const Games = {
    activePanelClass: '.slider__content.active',
    prevPanelClass: '.slider__content.prev',
    nextPanelClass: '.slider__content.next',
    activeTabClass: '.tabs_item.active',
    audioId: 'click',
    getActivePanel() {
        return document.querySelector(this.activePanelClass);
    },
    getPrevPanel() {
        return document.querySelector(this.prevPanelClass);
    },
    getNextPanel() {
        return document.querySelector(this.nextPanelClass);
    },
    getActiveTab() {
        return document.querySelector(this.activeTabClass);
    },
    getCurrentGroupId() {
        return this.getActivePanel().dataset.groupId;
    },
    getAudioElem() {
        return document.getElementById(this.audioId);
    },
    leftButton: undefined,
    leftFirstPanel: undefined,
    leftSecondPanel: undefined,
    leftSmoke: undefined,
    rightButton: undefined,
    rightFirstPanel: undefined,
    rightSecondPanel: undefined,
    rightSmoke: undefined,
    timelineCount: 0,
    timelineScale: 1,
    timelines: new Map(),
    direction: 'right',

    init() {
        this.leftButton = document.getElementById('games_button_left');
        this.leftFirstPanel = document.getElementById('games_left_slider_item');
        this.leftSecondPanel = document.getElementById('games_left_fake_panel');
        this.leftSmoke = document.getElementById('games_left_smoke');
        this.rightButton = document.getElementById('games_button_right');
        this.rightFirstPanel = document.getElementById('games_right_slider_item');
        this.rightSecondPanel = document.getElementById('games_right_fake_panel');
        this.rightSmoke = document.getElementById('games_right_smoke');

        const groupId = localStorage.getItem('groupId');
        const pageNumber = localStorage.getItem('pageNumber');
        let active = document.querySelector(`.slider__content[data-group-id="${groupId}"][data-page-number="${pageNumber}"]`);
        if (active) {
            let tab = document.querySelector(".tabs__item[data-group-id='" + groupId + "']");
            this.updateTabPage(tab, pageNumber);
        } else {
            active = document.querySelector('.slider__content');
        }
        active.classList.add('active');
        this.showGames(active);
        this.findPrevPanel(active).classList.add('prev');
        this.findNextPanel(active).classList.add('next');

        this.registerScrollHandlers();
    },

    registerScrollHandlers() {
        document.addEventListener('keydown', e => {
            if (e.key === 'ArrowLeft') {
                this.runAnimations('left');
            }
            if (e.key === 'ArrowRight') {
                this.runAnimations('right');
            }
        });
        this.leftButton.addEventListener('click', () => {
            this.runAnimations('left');
        });
        this.rightButton.addEventListener('click', () => {
            this.runAnimations('right');
        });
        document.querySelectorAll('.tabs__item').forEach(tab => {
            tab.addEventListener('click', () => {
                this.killAnimations().then(() => {
                    let groupId = tab.dataset.groupId;
                    let next = document.querySelector(".slider__content[data-group-id='" + groupId + "']");
                    if (groupId === this.getActivePanel().dataset.groupId
                        && this.getNextPanel().dataset.groupId === groupId
                    ) {
                        next = this.getNextPanel();
                    } else if (next === this.getActivePanel()) {
                        return;
                    }
                    this.getActivePanel().classList.remove('active');
                    this.getNextPanel().classList.remove('next');
                    this.getPrevPanel().classList.remove('prev');
                    next.classList.add('next');
                    let active = this.findPrevPanel(next);
                    active.classList.add('active');
                    let prev = this.findPrevPanel(active);
                    prev.classList.add('prev');
                    this.runAnimations('right')
                });
            });
        });
    },

    runAnimations(direction) {
        let active = this.getActivePanel();
        let prev = this.getPrevPanel();
        let next = this.getNextPanel();
        this.hideGames(active);
        if (this.timelineCount > 0) {
            return this.killAnimations().then(() => {
                this.runAnimations(direction)
            });
        }
        let toActive = {}, fromActive = {}, groupId, pageNumber;
        if (direction === 'right') {
            fromActive = { el: next, x: '-26.5%', rotateY: 11 };
            toActive = { el: active, x: '26.5%', rotateY: -11 };
            groupId = next.dataset.groupId;
            pageNumber = next.dataset.pageNumber;
        } else {
            fromActive = { el: prev, x: '26.5%', rotateY: -11 };
            toActive = { el: active, x: '-26.5%', rotateY: 11 };
            groupId = prev.dataset.groupId;
            pageNumber = prev.dataset.pageNumber;
        }
        let tab = document.querySelector(".tabs__item[data-group-id='" + groupId + "']");
        this.updateTabPage(tab, pageNumber);
        this.timelineCount++;
        let tl = gsap.timeline()
            /* TODO анимация дымка
            TODO анимация задних панелей
            .to(this.leftSmoke, {
                x: '-20%',
                opacity: 1,
                clearProps: 'all'
            }, 0)
            .to(this.rightSmoke, {
                x: '20%',
                opacity: 1,
                clearProps: 'all'
            }, 0)
            */
            .fromTo(fromActive.el, {
                z: 0.001,
                scaleX: 1,
                scaleY: 1,
                rotateY: 0,
                x: 0,
                opacity: 1,
            }, {
                scaleX: 0.5,
                scaleY: 0.8,
                rotateY: fromActive.rotateY,
                x: fromActive.x,
                opacity: 0.5,
                clearProps: 'all',
            }, 0)
            .fromTo(toActive.el, {
                z: 0.001,
                scaleX: 0.5,
                scaleY: 0.77,
                rotateY: toActive.rotateY,
                x: toActive.x,
                opacity: 0,
            }, {
                opacity: 1,
                x: 0,
                scaleX: 1,
                scaleY: 1,
                rotateY: 0,
                clearProps: 'all',
                onCompleteParams: [this.timelineCount],
                onComplete: (a) => {
                    this.timelines.delete(a);
                    this.timelineCount--;
                    if (this.timelineCount === 0) {
                        next.classList.remove('next');
                        active.classList.remove('active');
                        prev.classList.remove('prev');
                        if (direction === 'right') {
                            next.classList.add('active');
                            active.classList.add('prev');
                            prev = active;
                            active = next;
                            next = this.findNextPanel(active);
                            next.classList.add('next');
                        } else {
                            active.classList.add('next');
                            prev.classList.add('active');
                            next = active;
                            active = prev;
                            prev = this.findPrevPanel(active);
                            prev.classList.add('prev');
                        }
                        this.showGames(active);
                    }
                }
            }, 0)
            .timeScale(3);
        this.timelines.set(this.timelineCount, tl);
        const audio = this.getAudioElem();
        audio.load();
        audio.play();
    },

    findPrevPanel(panel) {
        let prev = panel.previousElementSibling;
        if (prev === null || (prev ? !prev.classList.contains('slider__content') : true)) {
            let panels = document.querySelectorAll('.slider__content');
            prev = panels[panels.length - 1];
        }
        return prev;
    },

    findNextPanel(panel) {
        let next = panel.nextElementSibling;
        if (next === null || (next ? !next.classList.contains('slider__content') : true)) {
            let panels = document.querySelectorAll('.slider__content');
            next = panels[0];
        }
        return next;
    },

    updateTabPage(tab, pageNumber) {
        if (!tab.classList.contains('active')) {
            document.querySelector('.tabs__item.active').classList.remove('active');
            tab.classList.add('active');
        }
        tab.querySelector('.tab-page').innerText = pageNumber;
        let main = document.querySelector('.main');
        let changed = false;
        main.classList.forEach(cls => {
            if (cls !== 'main' && cls !== tab.dataset.color) {
                main.classList.remove(cls);
                changed = true;
            }
        })
        if (changed) {
            main.classList.add(tab.dataset.color);
        }
    },

    showGames(panel) {
        this.rememberOpenedPanel(panel);
        panel
            .querySelectorAll('.slider__content-item')
            .forEach(image => {
                image.classList.add('active');
            });
    },

    rememberOpenedPanel(panel) {
        const groupId = panel.dataset.groupId;
        const pageNumber = panel.dataset.pageNumber;
        localStorage.setItem('groupId', groupId);
        localStorage.setItem('pageNumber', pageNumber);
    },

    hideGames(panel) {
        panel
            .querySelectorAll('.slider__content-item')
            .forEach(image => {
                image.classList.remove('active');
            });
    },

    killAnimations() {
        return new Promise(resolve => {
            let promises = [];
            this.timelines.forEach(tl => {
                tl.eventCallback('onComplete', () => {
                    promises.push(Promise.resolve());
                });
                tl.time(tl.duration());
            });
            Promise.all(promises).then(() => {
                this.timelines.clear();
                resolve();
            })
        })
    }
}

export default Games;
