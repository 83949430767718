const GBESession = {
    gbeStartSessionUrl: undefined,
    gbeResumeSessionUrl: undefined,
    mode: undefined,
    partnerUid: undefined,
    bankId: undefined,
    gameId: undefined,
    denominator: undefined,
    token: undefined,
    extendedSessionUid: undefined,
    getLocalStorageKey() {
        return `ExtendedSessionUID:${this.partnerUid}/${this.bankId}/${this.gameId}/${this.denominator}/${this.token}`;
    },
    setExtendedSessionUid(uid) {
        this.extendedSessionUid = uid;
        localStorage.setItem(this.getLocalStorageKey(), uid);
    },

    init(gbeStartSessionUrl, gbeResumeSessionUrl, { mode, gameId, partnerUid, bankId, token, denominator }) {
        this.gbeStartSessionUrl = gbeStartSessionUrl;
        this.gbeResumeSessionUrl = gbeResumeSessionUrl;
        this.mode = mode;
        this.partnerUid = partnerUid;
        this.bankId = bankId;
        this.gameId = gameId;
        this.denominator = denominator;
        this.token = token;
    },

    deleteLocalStorageKeys() {
        const prefix = this.getLocalStorageKey();
        const keys = [];
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).substring(0, prefix.length) === prefix) {
                keys.push(localStorage.key(i));
            }
        }

        for (let i = 0; i < keys.length; i++) {
            localStorage.removeItem(keys[i]);
        }
    },

    startSession() {
        this.deleteLocalStorageKeys();

        return window
            .axios
            .create({})
            .post(this.gbeStartSessionUrl, {
                mode: this.mode,
                gameId: this.gameId,
                partnerUid: this.partnerUid,
                bankId: this.bankId,
                token: this.token,
                denominator: this.denominator
            })
            .then(res => {
                if (res.data.extendedSessionUid) {
                    this.setExtendedSessionUid(res.data.extendedSessionUid);

                    return this.extendedSessionUid;
                } else {
                    throw Error("Unable to create session");
                }
            });
    },

    resumeSession() {
        if (localStorage.getItem(this.getLocalStorageKey())) {
            this.extendedSessionUid = localStorage.getItem(this.getLocalStorageKey());

            return window
                .axios
                .create({})
                .post(this.gbeResumeSessionUrl, null, {
                    headers: {
                        'X-EXTENDED-SESSION-UID': this.extendedSessionUid
                    }
                })
                .then(res => {
                    if (res.data.extendedSessionUid) {
                        this.setExtendedSessionUid(res.data.extendedSessionUid);

                        return this.extendedSessionUid;
                    } else {
                        throw Error("Unable to create session");
                    }
                });
        } else {
            return this.startSession();
        }
    }
}


export default GBESession;
