import axios from 'axios';
import PasswordGame from './passwordgame';
import Games from './games';
import GBESession from './gbe-session';
import PinPad from './pinpad';
import Platinrace from './platinrace';

window.axios = axios;

/**
 * @typedef AppContext
 * @property {PasswordGame} PasswordGame
 * @property {Games} Games
 * @property {GBESession} GBESession
 * @property {PinPad} PinPad
 * @property {Platinrace} Platinrace
 */

/**
 * @type {AppContext}
 */
const ctx = {
    PasswordGame,
    Games,
    GBESession,
    PinPad,
    Platinrace
};

/**
 * @callback initCallback
 * @param {AppContext} ctx
 */

/**
 * @param {initCallback} cb
 */
window.init = (cb) => {
    cb(ctx);
};

window.ctx = ctx;
